import mock from '../mock'
import { paginateArray } from '../utils'

const data = [
  {
    id : 1,
    prin : '442.131',
    sellto4G : '400.208',
    npv : '400.28',
    delta : '0',
    check : '599.142',
    fx : 'MXN',
    totalcf : '599.142',
    lessee : 'Doga_Res',
    cash1 : '0.00',
    cash2 : '0.00',
    cash3 : '0.00',
    cash4 : '0.00',
    cash5 : '0.00',
    cash6 : '0.00',
    cash7 : '0.00',
    cash8 : '0.00',
    cash9 : '0.00',
    cash10 : '0.00',
    cash11 : '0.00',
    cash12 : '0.00',
    cash13 : '0.00',
    cash14 : '0.00',
    cash15 : '0.00',
    cash16 : '0.00',
    cash17 : '0.00',
    cash18 : '0.00',
    cash19 : '0.00',
    cash20 : '0.00',
    cash21 : '0.00',
    cash22 : '0.00',
    cash23 : '0.00',
    cash24 : '0.00',
    cash25 : '0.00',
    cash26 : '0.00',
    cash27 : '0.00',
    cash28 : '0.00',
    cash29 : '0.00',
    cash30 : '0.00',
    cash31 : '0.00',
    cash32 : '0.00',
    cash33 : '0.00',
    cash34 : '0.00',
    cash35 : '0.00',
    cash36 : '0.00',
    cash37 : '0.00',
    cash38 : '0.00',
    cash39 : '0.00',
    cash40 : '0.00',
    cash41 : '0.00',
    cash42 : '0.00',
    cash43 : '0.00',
    cash44 : '0.00',
    cash45 : '0.00',
    cash46 : '0.00',
    cash47 : '0.00',
    cash48 : '0.00',
    cash49 : '0.00',
    cash50 : '0.00',
    cash51 : '0.00',
    cash52 : '0.00',
    cash53 : '0.00',
    cash54 : '0.00',
    cash55 : '0.00',
    cash56 : '0.00',
    cash57 : '0.00',
    cash58 : '0.00',
    cash59 : '0.00',
    cash60 : '0.00',
    cash61 : '0.00'
  },
  {
    id : 1,
    prin : '342.131',
    sellto4G : '300.208',
    npv : '300.28',
    delta : '0',
    check : '499.142',
    fx : 'MXN',
    totalcf : '499.142',
    lessee : 'Olesur',
    cash1 : '0.00',
    cash2 : '0.00',
    cash3 : '0.00',
    cash4 : '0.00',
    cash5 : '0.00',
    cash6 : '0.00',
    cash7 : '0.00',
    cash8 : '0.00',
    cash9 : '0.00',
    cash10 : '0.00',
    cash11 : '0.00',
    cash12 : '0.00',
    cash13 : '0.00',
    cash14 : '0.00',
    cash15 : '0.00',
    cash16 : '0.00',
    cash17 : '0.00',
    cash18 : '0.00',
    cash19 : '0.00',
    cash20 : '0.00',
    cash21 : '0.00',
    cash22 : '0.00',
    cash23 : '0.00',
    cash24 : '0.00',
    cash25 : '0.00',
    cash26 : '0.00',
    cash27 : '0.00',
    cash28 : '0.00',
    cash29 : '0.00',
    cash30 : '0.00',
    cash31 : '0.00',
    cash32 : '0.00',
    cash33 : '0.00',
    cash34 : '0.00',
    cash35 : '0.00',
    cash36 : '0.00',
    cash37 : '0.00',
    cash38 : '0.00',
    cash39 : '0.00',
    cash40 : '0.00',
    cash41 : '0.00',
    cash42 : '0.00',
    cash43 : '0.00',
    cash44 : '0.00',
    cash45 : '0.00',
    cash46 : '0.00',
    cash47 : '0.00',
    cash48 : '0.00',
    cash49 : '0.00',
    cash50 : '0.00',
    cash51 : '0.00',
    cash52 : '0.00',
    cash53 : '0.00',
    cash54 : '0.00',
    cash55 : '0.00',
    cash56 : '0.00',
    cash57 : '0.00',
    cash58 : '0.00',
    cash59 : '0.00',
    cash60 : '0.00',
    cash61 : '0.00'
  },
  {
    id : 1,
    prin : '242.131',
    sellto4G : '200.208',
    npv : '200.28',
    delta : '0',
    check : '399.142',
    fx : 'MXN',
    totalcf : '399.142',
    lessee : 'Impersealco',
    cash1 : '0.00',
    cash2 : '0.00',
    cash3 : '0.00',
    cash4 : '0.00',
    cash5 : '0.00',
    cash6 : '0.00',
    cash7 : '0.00',
    cash8 : '0.00',
    cash9 : '0.00',
    cash10 : '0.00',
    cash11 : '0.00',
    cash12 : '0.00',
    cash13 : '0.00',
    cash14 : '0.00',
    cash15 : '0.00',
    cash16 : '0.00',
    cash17 : '0.00',
    cash18 : '0.00',
    cash19 : '0.00',
    cash20 : '0.00',
    cash21 : '0.00',
    cash22 : '0.00',
    cash23 : '0.00',
    cash24 : '0.00',
    cash25 : '0.00',
    cash26 : '0.00',
    cash27 : '0.00',
    cash28 : '0.00',
    cash29 : '0.00',
    cash30 : '0.00',
    cash31 : '0.00',
    cash32 : '0.00',
    cash33 : '0.00',
    cash34 : '0.00',
    cash35 : '0.00',
    cash36 : '0.00',
    cash37 : '0.00',
    cash38 : '0.00',
    cash39 : '0.00',
    cash40 : '0.00',
    cash41 : '0.00',
    cash42 : '0.00',
    cash43 : '0.00',
    cash44 : '0.00',
    cash45 : '0.00',
    cash46 : '0.00',
    cash47 : '0.00',
    cash48 : '0.00',
    cash49 : '0.00',
    cash50 : '0.00',
    cash51 : '0.00',
    cash52 : '0.00',
    cash53 : '0.00',
    cash54 : '0.00',
    cash55 : '0.00',
    cash56 : '0.00',
    cash57 : '0.00',
    cash58 : '0.00',
    cash59 : '0.00',
    cash60 : '0.00',
    cash61 : '0.00'
  }
]

mock.onGet('/api/sqn/all-total-cash').reply(config => {
  return [200, data]
})

mock.onGet('/api/sqn/total-cash').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1 } = config
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.filter(
    item =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      item.full_name.toLowerCase().includes(queryLowered) ||
      item.post.toLowerCase().includes(queryLowered) ||
      item.email.toLowerCase().includes(queryLowered) ||
      item.age.toLowerCase().includes(queryLowered) ||
      item.salary.toLowerCase().includes(queryLowered) ||
      item.start_date.toLowerCase().includes(queryLowered)
  )
  /* eslint-enable  */

  return [
    200,
    {
      allData: data,
      invoices: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})
