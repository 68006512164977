export const SET_LOADING_V2 = 'SET_LOADING_VIEW_V2';

export const setLoadingApp = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_V2,
      payload
    });
  };
};

export const SET_COLLAPSE_MENU_V2 = 'SET_COLLAPSE_MENU_VIEW_V2';

export const setCollapseItems = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_COLLAPSE_MENU_V2,
      payload
    });
  };
};

// ** Get all Data Lease Combined
export const findNestJSQuotations = (
  token,
  status = '',
  idUser = '',
  setLoad,
  userData = null,
  filterLimit = null,
  currentPage = null,
  companyName = ''
) => {
  return async (dispatch) => {
    dispatch(setLoadingApp(true));

    const newStatus = status ? `status=${status}` : '';
    const newIdUser = idUser ? `id_auth0_user=${idUser}` : '';
    const newLimit = filterLimit ? `limit=${filterLimit}` : '';
    const newPage = currentPage ? `page=${currentPage}` : '';
    const exclude = userData.role === 'Dev' ? '' : 'authorRoleExclude[]=Dev';
    const company_name = companyName ? `company_name=${companyName}` : '';
    
    const urlQuotations = `${process.env.REACT_APP_NESTJS_API}/api/v1/quotations/filter?&sortByCreatedAt=-1${newStatus ? `&${newStatus}` : ''}${newIdUser ? `&${newIdUser}` : ''}${exclude ? `&${exclude}` : ''}${newLimit ? `&${newLimit}` : ''}${company_name ? `&${company_name}` : ''}${!company_name && newPage ? `&${newPage}` : ''}`;

    try {
      const response = await fetch(urlQuotations, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const resultJSON = await response.json();
      setLoad(false);
      dispatch({
        type: 'FIND_NEST_QUOTATIONS',
        data: resultJSON.items
      });
      dispatch({
        type: 'FILTERS_NEST_QUOTATIONS',
        data: resultJSON.meta
      });
    } catch (error) {
      console.error('Error while fetching quotations:', error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };
};

export const updateRowJSQuotations = (response) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_NEST_QUOTATIONS',
      data: response
    });
  };
}

export const newRowJSQuotations = (response) => {
  return (dispatch) => {
    dispatch({
      type: 'NEW_UPDATE_NEST_QUOTATIONS',
      data: response
    });
  };
}

export const getNestJSQuotations = (
  token,
  id,
  setShowPreview = () => {},
  setQuotation = () => {},
  setLoad = () => {},
  setAssetsLoad = () => {}
) => {
  if (!id) {
    return false;
  }
  return (dispatch) => {
    return fetch(
      `${process.env.REACT_APP_NESTJS_API}/api/v1/quotations/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ).then(async (response) => {
      const resultJSON = await response.json();
      dispatch({
        type: 'GET_NEST_QUOTATIONS',
        data: resultJSON
      });
      setLoad(false);
      setQuotation(resultJSON);
      setAssetsLoad(false);
      setShowPreview(true);
    });
  };
};

export const getNestJSSingleQuotations = (token, id) => {
  if (!id) {
    return false;
  }

  return async (dispatch) => {
    dispatch(setLoadingApp(true));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_NESTJS_API}/api/v1/quotations/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const resultJSON = await response.json();
      
      dispatch({
        type: 'GET_NEST_QUOTATIONS',
        data: resultJSON
      });
    } catch (error) {
      console.log('error', error)
    } finally {
      dispatch(setLoadingApp(false));
    }
  };
};

export const getNestJSLocalQuotations = (data) => {
  if (!data) {
    return false;
  }

  return async (dispatch) => {
    dispatch(setLoadingApp(true));
    dispatch({
      type: 'GET_NEST_QUOTATIONS',
      data: data
    });
    dispatch(setLoadingApp(false));
  };
};

export const GET_ASSUMP_LEASE = '[LEASE_DETAIL]_GET_ASSUMP_LEASE';

export const getNestJSQuotationsLease = (
  token,
  id,
  setShowPreview = () => {},
  setQuotation = () => {},
  setLoad = () => {},
  setAssetsLoad = () => {}
) => {
  if (!id) {
    return false;
  }
  return (dispatch) => {
    return fetch(
      `${process.env.REACT_APP_NESTJS_API}/api/v1/quotations/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ).then(async (response) => {
      const resultJSON = await response.json();
      console.log('resultJSON', resultJSON)
      const payload = resultJSON || {};
      const type = GET_ASSUMP_LEASE
      dispatch({ type, payload });
      setLoad(false);
      setQuotation(resultJSON);
      setAssetsLoad(false);
      setShowPreview(true);
    });
  };
};

export const getNestJSEmptyQuote = () => {
  return (dispatch) => {
    dispatch({
      type: 'GET_NEST_QUOTATIONS',
      data: {}
    });
  };
};

export const getQuotePrint = (data, useStorePreview = () => {}) => {
  return async (dispatch) => {
    useStorePreview(data);
    dispatch({
      type: 'GET_NEST_PRINT_QUOTATIONS',
      data
    });
  };
};

export const postNestJSQuotations = (
  token,
  data,
  useStorePreview,
  setLoad,
  setShowPreview
) => {
  return (dispatch) => {
    if (data._id) {
      delete data._id;
    }
    setLoad(true);
    return fetch(`${process.env.REACT_APP_NESTJS_API}/api/v1/quotations`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`
      }
    }).then(async (response) => {
      const resultJSON = await response.json();
      useStorePreview(resultJSON);
      setLoad(false);
      dispatch({
        type: 'POST_NEST_QUOTATIONS',
        data: resultJSON
      });
      dispatch(
        getNestJSQuotations(
          token,
          resultJSON._id,
          setShowPreview,
          useStorePreview,
          setLoad
        )
      );
    });
  };
};

export const patchNestJSQuotations = (
  token,
  id,
  data,
  useStorePreview,
  setLoad,
  setShowPreview,
  toggleModalValid = () => {},
  toggleModalOptions = () => {},
  getQuote = true
) => {
  return (dispatch) => {
    if (setLoad) {
      setLoad(true);
    }
    
    const removeProperties = (obj, properties) => {
      if (!obj) return; // Verifica si el objeto es undefined o null antes de continuar
      properties.forEach((prop) => {
        if (obj[prop]) {
          delete obj[prop];
        }
      });
    };
    
    // Eliminar propiedades no deseadas de 'data'
    removeProperties(data, ['_id', 'updatedAt', 'createdAt']);
    
    // Verifica si 'observations' existe antes de intentar eliminar propiedades
    removeProperties(data.observations, ['createdAt', 'updatedAt']);

    const newData = data;
    return fetch(
      `${process.env.REACT_APP_NESTJS_API}/api/v1/quotations/${id}`,
      {
        method: 'PUT',
        body: JSON.stringify(newData),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`
        }
      }
    ).then(async (response) => {
      const resultJSON = await response.json();
      useStorePreview(response.data);
      if (setLoad) {
      setLoad(false);
      }
      dispatch({
        type: 'PATCH_NEST_QUOTATIONS',
        data: resultJSON
      });
      if (getQuote) {
        dispatch(
          getNestJSQuotations(
            token,
            id,
            setShowPreview,
            useStorePreview,
            setLoad
          )
        );
      }
      if (toggleModalValid) {
        toggleModalValid();
      }
      if (toggleModalOptions) {
        toggleModalOptions();
      }
      
    });
  };
};

export const patchNestJSQuotationsStatus = (
  token,
  id,
  data,
  setLoad,
  history
) => {
  return (dispatch) => {
    setLoad(true);
    if (data._id) {
      delete data._id;
    }
    if (data.updatedAt) {
      delete data.updatedAt;
    }
    if (data.createdAt) {
      delete data.createdAt;
    }

    const newData = data;
    return fetch(
      `${process.env.REACT_APP_NESTJS_API}/api/v1/quotations/${id}`,
      {
        method: 'PUT',
        body: JSON.stringify(newData),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`
        }
      }
    ).then(async (response) => {
      const resultJSON = await response.json();
      setLoad(false);
      dispatch({
        type: 'PATCH_NEST_QUOTATIONS',
        data: resultJSON
      });
      if (history) history.push('/onboarding/quotations/list');
    });
  };
};

export const deleteNestJSQuotations = (token, id, refreshData) => {
  if (!id) {
    return false;
  }
  return (dispatch) => {
    return fetch(
      `${process.env.REACT_APP_NESTJS_API}/api/v1/quotations/${id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ).then(async (response) => {
      // await refreshData();
      dispatch({
        type: 'DELETE_NEST_QUOTATIONS',
        id: id
      });
    });
  };
};

export const socketSyncQueueBull = async (
  socket,
  payload,
  setQuotation,
  setLoad
) => {
  if (!payload) {
    return false;
  }

  console.log('Payload Quotation', payload)
  socket.emit('generateQuotation', payload);
};

export const socketSyncAssetsQuotation = async (socket, payload) => {
  if (!payload) {
    return false;
  }
  socket.emit('assetsQuotation', payload);
};

export const socketSyncEditQuotation = async (socket, payload) => {
  if (!payload) {
    return false;
  }

  socket.emit('editQuotation', { idQuotation: payload._id, ...payload });
};

export const socketUpdateQuote = (
  payload,
  setQuotation = () => {},
  setLoad = () => {}
) => {
  if (!payload) {
    return false;
  }
  return (dispatch) => {
    setQuotation(payload);
    setLoad(false);
    dispatch({
      type: 'SYNC_NEST_QUOTATIONS',
      data: payload
    });
  };
};

export const getQuoteToLease = ({
  id,
  token,
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
}) => {
  return (dispatch) => {
    return fetch(
      `${process.env.REACT_APP_NESTJS_API}/api/v1/sync-lease-v1/sync/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
      .then(async (response) => {
        const resultJSON = await response.json();
        dispatch({
          type: 'SYNC_NEST_QUOTE_LEASE',
          data: resultJSON
        });
        onSuccess(resultJSON);
      })
      .catch((e) => onError(e))
      .finally(() => onFinally());
  };
};

export const getAssets = (
  data,
  value,
  setSelectedMM = () => {},
  setLoad = () => {}
) => {
  if (!data) {
    return false;
  }
  return (dispatch) => {
    setLoad(false);
    setSelectedMM(value);
    dispatch({
      type: 'GET_DATA_ASSETS_QUOTATIONS',
      data: data
    });
  };
};

export const getAssetsEmpty = () => {
  return (dispatch) => {
    dispatch({
      type: 'GET_DATA_ASSETS_QUOTATIONS',
      data: []
    });
  };
};

export const patchNestJSQuotationsAssump = (
  token,
  id,
  data,
  setAssetsLoad,
  newSocket
) => {
  return (dispatch) => {
    setAssetsLoad(true);
    if (data._id) {
      delete data._id;
    }
    if (data.updatedAt) {
      delete data.updatedAt;
    }
    if (data.createdAt) {
      delete data.createdAt;
    }

    const newData = data;
    socketSyncEditQuotation(newSocket, { idQuotation: id, ...newData });
    // return fetch(
    //   `${process.env.REACT_APP_NESTJS_API}/api/v1/quotations/${id}`,
    //   {
    //     method: 'PUT',
    //     body: JSON.stringify(newData),
    //     headers: {
    //       'Content-type': 'application/json; charset=UTF-8',
    //       Authorization: `Bearer ${token}`
    //     }
    //   }
    // ).then(async (response) => {
    //   const resultJSON = await response.json();
    //   socketSyncEditQuotation(newSocket, { idQuotation: id, ...newData });
    //   dispatch({
    //     type: 'PATCH_NEST_QUOTATIONS',
    //     data: resultJSON
    //   });
    // });
  };
};
