import * as Actions from '../action';

const initialGlobal = {
  loading: false,
  socket: null,
  collapseItems: false
};

const initialState = {
  allData: [],
  allDataOnboardingV2: [],
  data: [],
  total: 1,
  params: {},
  selectedLease: null,
  selectLease: null,
  global: initialGlobal
};

const leaseCombined = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA_ONBOARDINGV1_LEASE':
      return {
        ...state,
        allDataOnboardingV2: action.data
      };
    case 'GET_ALL_DATA_LEASE':
      return { ...state, allData: action.data };
    case 'GET_DATA_LEASE':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      };
    case 'GET_LEASE':
      return { ...state, selectedLease: action.selectedLease };
    case 'ADD_LEASE':
      return { ...state };
    case 'DELETE_LEASE':
      return { ...state };
    case 'GET_NEST_LEASE':
      return { ...state, selectLease: action.data };
    case Actions.SET_LOADING_V2:
      return { 
        ...state,
        global: { ...state.global, loading: action.payload }
      };
    case Actions.SET_COLLAPSE_MENU_V2:
      return { 
        ...state,
        global: { ...state.global, collapseItems: action.payload }
      };
    default:
      return { ...state };
  }
};

export default leaseCombined;
