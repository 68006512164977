import * as Actions from '../actions';

const initialFormValues = {
  name: '',
  email: '',
  vat: '',
  is_company: false,
  country_id: '',
  city_id: '',
  state_id: '',
  zip: '',
  street_name: '',
  street_number: '',
  colony: ''
};

const initialGlobal = {
  loading: false,
  socket: null,
  collapseItems: false
};

const initialState = {
  constancyFile: null,
  form: initialFormValues,
  odooClient: null,
  total: 1,
  params: {},
  selectedLease: null,
  initialsConcepts: [],
  createInitialsConcepts: {},
  createInitialsConceptsLease: {},
  putInitialsConcepts: {},
  catInitialsClientsOdoo: [],
  conceptsOdoo: {},
  addClient: null,
  leaseEquipment: [],
  catEquipType: []
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_CONSTANCY_STORED_FILE:
      return {
        ...state,
        constancyFile: action.payload
      };
    case Actions.SET_CLIENT_FORM:
      return {
        ...state,
        form: action.payload
      };
    case Actions.RESET_CLIENT_STATE:
      return { ...initialState };
      case 'GET_NEST_LP_ODOO_CLIENT':
        return { ...state, odooClient: action.data }
      case 'GET_NEST_INITIALS_CONCEPTS':
        return { ...state, catInitialsConcepts: action.data }
      case 'GET_NEST_INITIALS_CONCEPTS_LEASE':
          return { ...state, catInitialsConceptsLease: action.data }
      case 'POST_NEST_INITIALS_CONCEPTS':
          return { ...state, createInitialsConcepts: action.data }
      case 'PUT_NEST_INITIALS_CONCEPTS':
          return { ...state, putInitialsConcepts: action.data }
      case 'POST_NEST_INITIALS_CONCEPTS_LEASE':
            return { ...state, createInitialsConceptsLease: action.data }
      case 'PUT_NEST_INITIALS_CONCEPTS':
            return { ...state, putInitialsConcepts: action.data }
      case 'GET_NEST_INITIALS_CLIENTS_ODOO':
            return { ...state, catInitialsClientsOdoo: action.data }
      // case 'GET_NEST_ODOO_CLIENT':
      //       return { ...state, odooClient: action.data }
      case 'POST_NEST_CONCEPTS_ODOO':
              return { ...state, conceptsOdoo: action.data }
      case 'PUT_NEST_ADD_CLIENT':
              return { ...state, updateClient: action.data }
      case 'GET_NEST_LEASE_EQUIPMENT':
              return { ...state, leaseEquipment: action.data }
      case 'CAT_EQUIP_TYPE':
              return { ...state, catEquipType: action.data }
        default:
          return { ...state };
  }
};
export default clientReducer;
