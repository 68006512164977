import mock from '../mock'
const data = {
  faqData: {
    // Lease Combined
    payment: {
      icon: 'CreditCard',
      title: 'Lease Combined',
      subtitle: 'Do you have any questions about the module?',
      qandA: [
        {
          question: 'How do I import a master file?',
          ans: '...'
        }
      ]
    },
    // Master File
    delivery: {
      icon: 'ShoppingBag',
      title: 'Master File',
      subtitle: 'Do you have any questions about the module?',
      qandA: [
        {
          question: 'How do I download a document??',
          ans: '...'
        }
      ]
    }
  }
}

mock.onGet('/faq/data').reply(config => {
  const { q = '' } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = {}

  Object.entries(data.faqData).forEach(entry => {
    const [categoryName, categoryObj] = entry
    const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
      return qAndAObj.question.toLowerCase().includes(queryLowered)
    })
    if (filteredQAndAOfCategory.length) {
      filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
    }
  })

  return [200, filteredData]
})
