import axios from 'axios';

// Tipos de Acciones
export const SET_CONSTANCY_STORED_FILE = 'SET_CONSTANCY_STORED_FILE';
export const SET_CLIENT_FORM = 'SET_CLIENT_FORM';
export const RESET_CLIENT_STATE = 'RESET_CLIENT_STATE';
export const GET_NEST_LP_ODOO_CLIENT = 'GET_NEST_LP_ODOO_CLIENT';

// Acciones
export const setConstancyStoredFile = (payload) => ({
  type: SET_CONSTANCY_STORED_FILE,
  payload
});


export const getNullIncludeClient = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'GET_NEST_LP_ODOO_CLIENT',
      data: null
    })
  };
}

export const getLeaseIncludeCliente = (id, token) => {
  return (dispatch, getState) => {
    axios({
      url: `${process.env.REACT_APP_NESTJS_API}/api/v1/leaseparameters/${id}`,
      params: { includeOdooUserClient: true },
      headers: {
        authorization: `Bearer ${token}`
      }
    }).then(({ data }) => {
      dispatch({
        type: GET_NEST_LP_ODOO_CLIENT,
        data
      });
    })
  };
};

export const getAllDataOnboardingLease = (token, filter) => {
  return dispatch => {
    return fetch(`${process.env.REACT_APP_NESTJS_API}/api/v1/leaseparameters?limit=10000&page=1`, {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`
      }
    }).then(async (response) => {
      const resultJSON = await response.json()
      dispatch({
        type: 'GET_ALL_DATA_ONBOARDINGV1_LEASE',
        data: resultJSON.items
      })
    }).catch(err => console.log(err))
  }
}

export const getNestJSInitialsConcepts = (token) => {
  return dispatch => {
    return fetch(`${process.env.REACT_APP_NESTJS_API}/api/v1/lu-concept`, {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`
      }
    }).then(async (response) => {
      const resultJSON = await response.json()
      dispatch({
        type: 'GET_NEST_INITIALS_CONCEPTS',
        data: resultJSON
      })
    })
  }
}

export const getNestJSInitialsConceptsLease = (token, id) => {
  return dispatch => {
    return fetch(`${process.env.REACT_APP_NESTJS_API}/api/v1/concept-lease?limit=100&page=1&leaseId=${id}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json;",
        Authorization: `Bearer ${token}`
      }
    }).then(async (response) => {
      const resultJSON = await response.json()
      dispatch({
        type: 'GET_NEST_INITIALS_CONCEPTS_LEASE',
        data: resultJSON
      })
    })
  }
}

export const getNestJSClientsOdoo = (token) => {
  return dispatch => {
    return fetch(`${process.env.REACT_APP_NESTJS_API}/api/v1/odoo/user-clients?limit=2500&page=1`, {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`
      }
    }).then(async (response) => {
      const resultJSON = await response.json()
      dispatch({
        type: 'GET_NEST_INITIALS_CLIENTS_ODOO',
        data: resultJSON
      })
    })
  }
}

export const postNestJSInitialsConcepts = (token, data, setLoad = () => {}) => {
  return async dispatch => {
    setLoad(true);
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_NESTJS_API}/api/v1/lu-concept`,
      data: data,
      headers: {
        "Content-type": "application/json;",
        authorization: `Bearer ${token}`
      }
    }).then(async ({data}) => {
      const resultJSON = data;
      dispatch({
        type: 'POST_NEST_INITIALS_CONCEPTS',
        data: resultJSON
      })
      dispatch(getNestJSInitialsConcepts(token))
    })
    
  }
  
}

export const putNestJSCreateClientOdoo = (newClient, token) => {
  return async dispatch => {
    return axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_NESTJS_API}/api/v1/odoo/user-clients/bulk`,
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`
      },
      data: { arrayBody: [newClient] }
    }).then((response) => {
      if (response?.data?.results) {
        if (response?.data?.results[0]?.status === 'rejected') {
          console.log('error')
        } else {
          dispatch({
            type: 'PUT_NEST_ADD_CLIENT',
            data: response?.data?.results[0]
          })
        }
      }
    })
    .catch((err) => {
      console.error({ err });
    })
    .finally(() => {
      console.log('finaly')
    });
}
}


export const getEquipTypeCatalog = () => {
  return async dispatch => {
    try {
      const dataTemp = [
        {
          value: 1,
          label: "CNC"
        },
        {
          value: 7,
          label: "Food Processing"
        },
        {
          value: 4,
          label: "Laser Cutter"
        },
        {
          value: 9,
          label: "Other Industrial"
        }, {
          value: 3,
          label: "Plastic Extrusion"
        }, {
          value: 2,
          label: "Plastic Injection"
        }, {
          value: 5,
          label: "Printer"
        }, {
          value: 6,
          label: "Water Jet Cutter"
        }, {
          value: 8,
          label: "Yellow Equipment"
        }
      ];
      dispatch({
        type: 'CAT_EQUIP_TYPE',
        data: dataTemp
      })
      // const Service = feathers.service('equip-catalog/type');
      // await Service.find({
      //   query: {
      //     $limit: 8000
      //   }
      // }).then((response) => {
      //   console.log("rresponse...", response)
      //   dispatch({
      //     type: 'CAT_EQUIP_TYPE',
      //     data: response
      //   })  
      // }).catch(err => console.log(err))
    } catch (error) {
      console.log("Error in catalog: EquipCatalogDesc", error)
    }
    
  }
}