import * as Actions from '../actions';

const initialFormValues = {};

const initialState = {
  dataConceptLease: null,
  form: initialFormValues
};

const conceptLeaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.FIND_LV2_LEASE_CONCEPT:
      return {
        ...state,
        dataConceptLease: action.payload
      };
    case Actions.POST_LV2_LEASE_CONCEPT:
      return {
        ...state,
        form: action.payload
      };
    case Actions.PUT_LV2_LEASE_CONCEPT:
      return {
        ...state,
        form: action.payload
      };
    case Actions.RESET_LV2_LEASE_CONCEPT:
      return { ...initialState };
    default:
      return { ...state };
  }
};
export default conceptLeaseReducer;
