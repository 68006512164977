
/* Utils */
const removeUnwantedProperties = (data) => {
  if (data?._id) {
    delete data._id;
  }
  if (data?.updatedAt) {
    delete data.updatedAt;
  }
  if (data?.createdAt) {
    delete data.createdAt;
  }

  const newData = data;
  return newData;
}

export const patchQuotationsV2 = (
  token,
  id,
  data
) => {
  return (dispatch) => {
    const newData = removeUnwantedProperties(data);
    return fetch(
      `${process.env.REACT_APP_NESTJS_API}/api/v1/quotations/${id}`,
      {
        method: 'PUT',
        body: JSON.stringify(newData),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`
        }
      }
    ).then(async (response) => {
      const resultJSON = await response.json();
      dispatch({
        type: 'GET_NEST_QUOTATIONS',
        data: resultJSON
      });
    });
  };
};
