// ** Initial State
const initialState = {
  pmsUser: {},
  catEquipDesc: [],
  catEquipType: [],
  catType: [],
  catEquipFx: [],
  initialsConcepts: [],
  catInitialsConcepts: [],
  catInitialsConceptsLease: [],
  catInitialsClientsOdoo: []
}

const general = (state = initialState, action) => {
  switch (action.type) {
    case 'PMS_USER_DATA':
      return { ...state, pmsUser: action.data }
    case 'CAT_EQUIP_DESC':
      return { ...state, catEquipDesc: action.data }
    case 'CAT_EQUIP_TYPE':
      return { ...state, catEquipType: action.data }
    case 'CAT_TYPE':
        return { ...state, catType: action.data }
    case 'CAT_EQUIP_FX':
      return { ...state, catEquipFx: action.data }
    case 'GET_NEST_INITIALS_CONCEPTS':
        return { ...state, catInitialsConcepts: action.data }
    case 'GET_NEST_INITIALS_CONCEPTS_LEASE':
        return { ...state, catInitialsConceptsLease: action.data }
    case 'GET_NEST_INITIALS_CLIENTS_ODOO':
        return { ...state, catInitialsClientsOdoo: action.data }
    default:
      return { ...state }
  }
}
export default general
