import axios from 'axios';
import feathers from '../../../../../feathers';

export * from './leaseDetail.actions';

// ** Get all Data Lease Combined
export const getAllDataLeaseCombined = () => {
  return async (dispatch) => {
    const leaseCombinedService = feathers.service('lease-combined');
    await leaseCombinedService
      .find({
        query: {
          $limit: 0,
          read: false
        }
      })
      .then((dataLease) => {
        dispatch({
          type: 'GET_ALL_DATA_LEASE',
          data: dataLease
        });
      })
      .catch((err) => console.log(err));
  };
};

// ** Get data on page or row change Lease Combines
export const getDataLeaseCombined = (params) => {
  return async (dispatch) => {
    const leaseCombinedService = feathers.service('lease-combined');
    await leaseCombinedService
      .find({
        query: {
          $limit: 0,
          read: false
        }
      })
      .then((dataLease) => {
        dispatch({
          type: 'GET_DATA_LEASE',
          data: dataLease,
          totalPages: dataLease.length,
          params
        });
      })
      .catch((err) => console.log(err));
  };
};

export const getLeaseCombined = (id) => {
  return async (dispatch) => {
    const leaseCombinedService = feathers.service('lease-combined');
    await leaseCombinedService
      .get(id)
      .then((dataLease) => {
        console.log('dataLease', dataLease);
        dispatch({
          type: 'GET_LEASE',
          selectedLease: dataLease
        });
      })
      .catch((err) => console.log(err));
  };
};

export const addLeaseCombined = (lease) => {
  return (dispatch, getState) => {
    axios
      .post('/apps/lease-combined/add-lease', lease)
      .then((response) => {
        dispatch({
          type: 'ADD_LEASE',
          lease
        });
      })
      .then(() => {
        dispatch(getDataLeaseCombined(getState().leasecombined.params));
        dispatch(getAllDataLeaseCombined());
      })
      .catch((err) => console.log(err));
  };
};

export const SET_LOADING_V2 = 'SET_LOADING_VIEW_V2';

export const setLoadingApp = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_V2,
      payload
    });
  };
};

export const getNestJSLocalLease = (data) => {
  if (!data) {
    return false;
  }

  return async (dispatch) => {
    dispatch(setLoadingApp(true));
    dispatch({
      type: 'GET_NEST_LEASE',
      data: data
    });
    dispatch(setLoadingApp(false));
  };
};

export const SET_COLLAPSE_MENU_V2 = 'SET_COLLAPSE_MENU_VIEW_V2';

export const setCollapseItems = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_COLLAPSE_MENU_V2,
      payload
    });
  };
};

export const getAllDataOnboardingLease = (token, filter) => {
  return dispatch => {
    dispatch(setLoadingApp(true));
    return fetch(`${process.env.REACT_APP_NESTJS_API}/api/v1/leaseparameters?limit=10000&page=1`, {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`
      }
    }).then(async (response) => {
      const resultJSON = await response.json()
      dispatch({
        type: 'GET_ALL_DATA_ONBOARDINGV1_LEASE',
        data: resultJSON.items
      })
      dispatch(setLoadingApp(false));
    }).catch(err => console.log(err))
  }
}