import axios from 'axios';

export const HANDLE_STEP = '[GENERATE_CALCULATOR]_HANDLE_STEP';
export const SET_SYNC_LOADING = '[GENERATE_CALCULATOR]_SET_SYNC_LOADING';
export const SET_SELECTED_ID_CLIENT = '[GENERATE_CALCULATOR]_SET_SELECTED_ID_CLIENT';
export const SET_SYNC_ERROR = '[GENERATE_CALCULATOR]_SET_SYNC_ERROR';
export const SET_CALCULATOR_PROGRESS =
  '[GENERATE_CALCULATOR]_SET_CALCULATOR_PROGRESS';
export const SET_QUOTATION_DATA = '[GENERATE_CALCULATOR]_SET_QUOTATION_DATA';
export const SET_QUERY_RESPONSE = '[GENERATE_CALCULATOR]_SET_QUERY_RESPONSE';
export const CLEAR_CALCULATOR_STATE =
  '[GENERATE_CALCULATOR]_CLEAR_CALCULATOR_STATE';

export const handleStep = (action) => {
  return (dispatch, getState) => {
    const { activeStep } = getState().quotationsNestJS.calculator;
    dispatch({
      type: HANDLE_STEP,
      payload:
        action === 'next'
          ? activeStep + 1
          : action === 'previous'
          ? activeStep - 1
          : action
    });
  };
};

export const setSyncLoading = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_SYNC_LOADING,
      payload
    });
  };
};

export const setSelectedIdClient = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_ID_CLIENT,
      payload
    });
  };
};

export const setSyncError = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_SYNC_ERROR,
      payload
    });
  };
};

export const setCalculatorProgress = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_CALCULATOR_PROGRESS,
      payload
    });
  };
};

export const setQuotationData = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_QUOTATION_DATA,
      payload
    });
  };
};

export const setQueryResponse = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_QUERY_RESPONSE,
      payload
    });
  };
};

export const clearCalculatorState = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CALCULATOR_STATE
    });
  };
};

export const downloadQuotation = ({
  quotation,
  token,
  queryNumber = 0,
  onSuccess = () => {}
}) => {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_NESTJS_API}/api/v1/quotations/download/${quotation?._id}?outputType=xlsx&type=EXCEL_LEASE`, {
      headers: {
        authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error en la solicitud');
        }
        return response.json();
      })
      .then((data) => {
        dispatch(
          setQueryResponse({
            queryNumber,
            response: { ...data, result: 'success' }
          })
        );
        onSuccess();
        dispatch(handleStep('next'));
      })
      .catch((err) => {
        dispatch(setSyncError(true));
        dispatch(
          setQueryResponse({
            queryNumber,
            response: { ...err, result: 'error' }
          })
        );
      })
      .finally(() => {
        dispatch(setSyncLoading(false));
      });
  };
};
/* TODO: Add filters */
export const getQuotationClients = ({ token }) => {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_NESTJS_API}/api/v1/odoo/user-clients?limit=2500&page=1`,
      headers: {
        authorization: `Bearer ${token}`
      }
    })
      .then(({ data }) => {
        dispatch(
          setQueryResponse({
            queryNumber: 2,
            response: { ...data, result: 'success' }
          })
        );
      })
      .catch((err) => {
        dispatch(
          setQueryResponse({
            queryNumber: 2,
            response: { ...err, result: 'error' }
          })
        );
      })
      .finally(() => {
        dispatch(setSyncLoading(false));
      });
  };
};

export const postQuotationDraftClient = ({ token, nameClient, setClientComponent, setLoadingClient }) => {
  return (dispatch) => {
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_NESTJS_API}/api/v1/odoo/user-clients/bulk`,
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`
      },
      data: {
        arrayBody: [
          {
            name: nameClient,
            countryId: 156,
            cityId: 267,
            stateId: 493,
            zip: "-",
            streetName: "-",
            streetNumber: 300,
            l10nMxEdiColony: "-",
            email: "test@quantumlease.com",
            lang: "es_MX",
            isCompany: true,
            vat: "QUAN010101000"
          }
        ]
      }
    })
      .then(({ data }) => {
        dispatch(
          getQuotationClients({ token })
        );
        setClientComponent('Select')
        setLoadingClient(false)
      })
      .catch((err) => {
        dispatch(
          setQueryResponse({
            queryNumber: 2,
            response: { ...err, result: 'error' }
          })
        );
      })
      .finally(() => {
        dispatch(
          getQuotationClients({ token })
        );
        dispatch(setSyncLoading(false));
      });
  };
};

export const getInitialExpenses = ({ token }) => {
  return (dispatch, getState) => {
    const { quotation } = getState().quotationsNestJS.syncQuote;
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_NESTJS_API}/api/v1/concept-lease/initial-expenses/${quotation?.idLease}`,
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        dispatch(
          setQueryResponse({
            queryNumber: '3.3',
            response: { ...response, result: 'success' }
          })
        );
        dispatch(setCalculatorProgress(75));
        setTimeout(() => {
          dispatch(
            downloadQuotation({
              quotation,
              token,
              queryNumber: '3.4',
              onSuccess: () => {
                dispatch(setCalculatorProgress(100));
                dispatch(setSyncLoading(false));
              }
            })
          );
        }, 1500);
      })
      .catch((err) => {
        dispatch(setSyncError(true));
        dispatch(
          setQueryResponse({
            queryNumber: '3.3',
            response: { ...err, result: 'error' }
          })
        );
      })
      .finally(() => dispatch(setSyncLoading(false)));
  };
};

export const validateSync = ({ token }) => {
  return (dispatch, getState) => {
    const { quotation } = getState().quotationsNestJS.syncQuote;
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_NESTJS_API}/api/v1/sync-lease-v1/validate-sync`,
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`
      },
      data: { idLease: quotation?.idLease, idQuotation: quotation?._id }
    })
      .then((response) => {
        dispatch(
          setQueryResponse({
            queryNumber: '3.2',
            response: { ...response, result: 'success' }
          })
        );
        dispatch(setCalculatorProgress(50));
        setTimeout(() => {
          dispatch(getInitialExpenses({ token }));
        }, 1500);
      })
      .catch((err) => {
        dispatch(setSyncError(true));
        dispatch(
          setQueryResponse({
            queryNumber: '3.2',
            response: { ...err, result: 'error' }
          })
        );
      })
      .finally(() => dispatch(setSyncLoading(false)));
  };
};

export const generateCalculator = ({ token }) => {
  return (dispatch) => {
    dispatch(validateSync({ token }));
  };
};

export const putLeaseParameters = ({ token, idLease }) => {
  return (dispatch, getState) => {
    const { idClient } = getState().quotationsNestJS.calculator;
    axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_NESTJS_API}/api/v1/leaseparameters/${idLease}`,
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`
      },
      data: { idClient: idClient }
    })
      .then(({ data }) => {
        console.log('Client Update Lease', idLease, idClient)
      })
      .catch((err) => {
        dispatch(
          setQueryResponse({
            queryNumber: 3.1,
            response: { ...err, result: 'error' }
          })
        );
      })
      .finally(() => {
        dispatch(setSyncLoading(false));
      });
  };
};