import * as Actions from '../actions/user.actions';

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null,
  userForm: {
    enabled: false,
    type: '',
    data: undefined
  },
  opsAnalistaData:  []
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_OPS_ANALISTA_DATA':
        return { ...state, opsAnalistaData: action.data }
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      };
    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser };
    case 'ADD_USER':
      return { ...state };
    case 'DELETE_USER':
      return { ...state };
    case Actions.HANDLE_USER_FORM:
      return { ...state, userForm: { ...action.payload } };
    default:
      return { ...state };
  }
};
export default users;
