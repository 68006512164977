import axios from 'axios';

/**
 * It makes a request with intercepted token.
 * @param {string} uri Contain path params & can receive query params too.
 * @param {Object} props Every property will be spread in request options, like headers, for example.
 * It can even include query property, which is an object with every queryParam to concatenate in uri.
 * @param {string} method Request method.
 * @returns {promise} A promise as a result from the excecuted request.
 *  */
export const makeQuantumRequest = async (uri, props = {}, token, method = 'GET') => {
  if (!uri) {
    return Promise.reject(new Error('Bad URI on call'));
  }

  let completeUri = uri;
  if (props.query) {
    const query = Object.entries(props.query)
      .filter(([_, val]) => val)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');

    completeUri = `${uri}?${query}`;
  }

  try {
    const response = await axios({
      method,
      url: `${process.env.REACT_APP_NESTJS_API}/api/v1${completeUri}`,
      headers: {
        authorization: `Bearer ${token}`
      },
      ...props
    });

    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};