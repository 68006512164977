import io from 'socket.io-client';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
require("dotenv").config();

// const socket = io(process.env.REACT_APP_FEATHERS_API, {
//   transports: ['websocket'],
//   forceNew: true
// });
// const client = feathers();

// client.configure(socketio(socket, {
//   timeout: 390000
// }));
const client = null;

export default client;