export const SET_CONSTANCY_STORED_FILE = '[CLIENT]_SET_CONSTANCY_STORED_FILE';
export const setConstancyStoredFile = (payload) => {
  return { type: SET_CONSTANCY_STORED_FILE, payload };
};

export const SET_CLIENT_FORM = '[CLIENT]_SET_CLIENT_FORM';
export const setClientForm = (payload) => {
  return { type: SET_CLIENT_FORM, payload };
};

export const RESET_CLIENT_STATE = '[CLIENT]_RESET_CLIENT_STATE';
export const resetClientState = () => {
  return { type: RESET_CLIENT_STATE };
};
