// ** Initial State
const initialState = {
  allData: [],
  allDataOnboardingV1: [],
  data: [],
  total: 1,
  params: {},
  selectedLease: null,
  addOnboardingQuotation: null,
  asyncOnboardingQuotation: null,
  patchOnboardingQuotation:null,
  patchOnboardingQuotationStatus:null,
  patchOnboardingParameters: null,
  selectedQuotation: null,
  initialsConcepts: [],
  createInitialsConcepts: {},
  createInitialsConceptsLease: {},
  putInitialsConcepts: {},
  catInitialsClientsOdoo: [],
  conceptsOdoo: {},
  odooClient: null
}

const leaseCombinedMF = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data };
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      };
    case 'GET_LEASE':
      return { ...state, selectedLease: action.selectedLease };
    case 'ADD_LEASE':
      return { ...state };
    case 'DELETE_LEASE':
      return { ...state };
    case 'GET_ALL_DATA_ONBOARDINGV1_LEASE':
      return {
        ...state,
        allDataOnboardingV1: action.data
      };
    case 'GET_ALL_DATA_ONBOARDINGV1_QUOTATIONS':
      return {
        ...state,
        allDataOnboardingQuotation: action.data
      };
    case 'POST_ADD_ONBOARDINGV1_QUOTATIONS':
      return {
        ...state,
        addOnboardingQuotation: action.data
      };
    case 'PATCH_ONBOARDINGV1_QUOTATIONS':
        return {
          ...state,
          patchOnboardingQuotation: action.data
        };
    case 'PATCH_ONBOARDINGV1_PARAMETERS':
      return {
        ...state,
        patchOnboardingParameters: action.data
      };
    case 'POST_SYNC_ONBOARDINGV1_QUOTATIONS':
      return {
        ...state,
        syncOnboardingQuotation: action.data
      };
    case 'PATCH_ONBOARDINGV1_QUOTATIONS_STATUS':
      return {
        ...state,
        patchOnboardingQuotationStatus: action.data
      };
    case 'GET_QUOTATION':
      return {
        ...state,
        selectedQuotation: action.selectedQuotation
      };
    case 'GET_NEST_INITIALS_CONCEPTS':
      return { ...state, catInitialsConcepts: action.data }
    case 'GET_NEST_INITIALS_CONCEPTS_LEASE':
        return { ...state, catInitialsConceptsLease: action.data }
    case 'POST_NEST_INITIALS_CONCEPTS':
        return { ...state, createInitialsConcepts: action.data }
    case 'PUT_NEST_INITIALS_CONCEPTS':
        return { ...state, putInitialsConcepts: action.data }
    case 'POST_NEST_INITIALS_CONCEPTS_LEASE':
          return { ...state, createInitialsConceptsLease: action.data }
    case 'PUT_NEST_INITIALS_CONCEPTS':
          return { ...state, putInitialsConcepts: action.data }
    case 'GET_NEST_INITIALS_CLIENTS_ODOO':
          return { ...state, catInitialsClientsOdoo: action.data }
    case 'GET_NEST_ODOO_CLIENT':
          return { ...state, odooClient: action.data }
    case 'POST_NEST_CONCEPTS_ODOO':
            return { ...state, conceptsOdoo: action.data }
    default:
      return { ...state };
  }
}
export default leaseCombinedMF
