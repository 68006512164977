
const initialState = {
  adminReport: [],
  loading: false,
  data: [],
  total: 1,
  params: {}
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FIND_NEST_REPORTS':
      return { ...state, adminReport: action.data };
    case 'POST_NEST_REPORTS':
      return { ...state, newReport: action.data };
    case 'SET_REPORT_LOADING_V2':
      return { ...state, loading: action.payload };
    default:
      return { ...state };
  }
};
export default reportsReducer;
