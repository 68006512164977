const initialState = {
  funderId: 1 // undefined || null = All
};

const funderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FUNDER':
      return {
        ...state,
        funderId: action.payload
      };
    default:
      return state;
  }
};

export default funderReducer;
