import { makeQuantumRequest } from '../../../../../auth/request/makeRequest';
// import axios from '../../../utility/axios-instance';
import axios from 'axios';

export const HANDLE_USER_FORM = '[USER]_HANDLE_USER_FORM';

export const getAllData = (token) => {
  return async (dispatch) => {
    await axios.get(`${process.env.REACT_APP_NESTJS_API}/api/v1/user`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      dispatch({
        type: 'GET_ALL_DATA',
        data: response.data.items
      });
    });
  };
};


export const getOpsAnalista = (token) => {
  return async (dispatch) => {
    try {
      const params = { rol: 'OpsAnalista', limit: 200 };
      const response = await axios.get(`${process.env.REACT_APP_NESTJS_API}/api/v1/user`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch({
        type: 'GET_OPS_ANALISTA_DATA',
        data: response.data.items
      });
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  }
}

export const getData = (params, token) => {
  return async dispatch => {
    await axios.get(`${process.env.REACT_APP_NESTJS_API}/api/v1/user?limit=100`, { 
        params: params, 
        headers: {
          Authorization: `Bearer ${token}`
        }
    }).then(response => {
      dispatch({
        type: 'GET_DATA',
        data: response.data.items,
        totalPages: response.data.meta.totalItems,
        params
      });
    });
  };
};

export const getUser = (id) => {
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_NESTJS_API}/api/v1/user`, { id })
      .then((response) => {
        dispatch({
          type: 'GET_USER',
          selectedUser: response.data.user
        });
      })
      .catch((err) => console.log(err));
  };
};

export const addUser = ({
  user,
  onSuccess = () => {},
  handlePagination = () => {},
  onError = () => {},
  onFinish = () => {},
  token
}) => {
  return () => {
    makeQuantumRequest(
      '/user/bulk',
      { data: { arrayBody: [user] } },
      token,
      'POST'
    )
      .then((res) => {
        console.log({ res });
        onSuccess();
        setTimeout(() => {
          handlePagination();
        }, 3000);
      })
      .catch((err) => {
        console.log({ err });
        onError();
      })
      .finally(() => onFinish());
  };
};

export const updateUser = ({
  user,
  onSuccess = () => {},
  handlePagination = () => {},
  onError = () => {},
  onFinish = () => {},
  token
}) => {
  return (e) => {
    makeQuantumRequest(
      '/user/bulk',
      { data: { arrayBody: [user] } },
      token,
      'PUT'
    )
      .then((res) => {
        console.log({ res });
        onSuccess();
        setTimeout(() => {
          handlePagination();
        }, 3000);
      })
      .catch((err) => {
        console.log({ err });
        onError();
      })
      .finally(() => onFinish());
  };
};

export const deleteUser = (id) => {
  return (dispatch, getState) => {
    axios
      .delete(`${process.env.REACT_APP_NESTJS_API}/api/v1/user`, { id })
      .then((response) => {
        dispatch({
          type: 'DELETE_USER'
        });
      })
      .then(() => {
        dispatch(getData(getState().users.params));
        dispatch(getAllData());
      });
  };
};

export const handleUserForm = ({
  enabled = false,
  type = '',
  data = undefined
} = {}) => {
  return (dispatch) => {
    dispatch({ type: HANDLE_USER_FORM, payload: { enabled, type, data } });
  };
};
