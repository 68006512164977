import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
// import useJwt from '@src/auth/jwt/useJwt'

// const config = useJwt.jwtConfig
// localStorage.removeItem('userData')
//     localStorage.removeItem(config.storageTokenKeyName)
//     localStorage.removeItem(config.storageRefreshTokenKeyName)

const userData = JSON.parse(window.localStorage.getItem('userData'))
const existingAbility = userData ? userData.ability : null

export default new Ability(existingAbility || initialAbility)
