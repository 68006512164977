import * as Actions from '../actions';

const initialFormValues = {
  name: '',
  email: '',
  vat: '',
  is_company: false,
  country_id: '',
  city_id: '',
  state_id: '',
  zip: '',
  street_name: '',
  street_number: '',
  colony: ''
};

const initialState = {
  constancyFile: null,
  form: initialFormValues
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_CONSTANCY_STORED_FILE:
      return {
        ...state,
        constancyFile: action.payload
      };
    case Actions.SET_CLIENT_FORM:
      return {
        ...state,
        form: action.payload
      };
    case Actions.RESET_CLIENT_STATE:
      return { ...initialState };
    default:
      return { ...state };
  }
};
export default clientReducer;
