import * as Actions from '../action';

const initialGlobal = {
  loading: false,
  socket: null,
  collapseItems: false
};

const initialState = {
  allData: [],
  allDataCxCV2: [],
  data: [],
  total: 1,
  params: {},
  selectedCxC: null,
  selectCxC: null,
  global: initialGlobal
};

const expedientCombined = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA_AUDITABLESV1_LEASE':
      return {
        ...state,
        allDataCxCV2: action.data
      };
    case 'GET_ALL_DATA_AUDITABLE':
      return { ...state, allData: action.data };
    case 'GET_DATA_AUDITABLE':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      };
    case 'GET_AUDITABLE':
      return { ...state, selectedCxC: action.selectedCxC };
    case 'ADD_AUDITABLE':
      return { ...state };
    case 'DELETE_AUDITABLE':
      return { ...state };
    case 'GET_NEST_AUDITABLE':
      return { ...state, selectCxC: action.data };
    case Actions.SET_LOADING_V2:
      return { 
        ...state,
        global: { ...state.global, loading: action.payload }
      };
    case Actions.SET_COLLAPSE_MENU_V2:
      return { 
        ...state,
        global: { ...state.global, collapseItems: action.payload }
      };
    default:
      return { ...state };
  }
};

export default expedientCombined;
