export const SET_LOADING_V2 = 'SET_LOADING_VIEW_V2';

export const setLoadingApp = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_V2,
      payload
    });
  };
};

export const getNestJSLocalLease = (data) => {
  if (!data) {
    return false;
  }

  return async (dispatch) => {
    dispatch(setLoadingApp(true));
    dispatch({
      type: 'GET_NEST_EXPEDIENT',
      data: data
    });
    dispatch(setLoadingApp(false));
  };
};

export const SET_COLLAPSE_MENU_V2 = 'SET_COLLAPSE_MENU_VIEW_V2';

export const setCollapseItems = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_COLLAPSE_MENU_V2,
      payload
    });
  };
};

export const getAllDataExpedientLease = (token, filter) => {
  return dispatch => {
    dispatch(setLoadingApp(true));
    return fetch(`${process.env.REACT_APP_NESTJS_API}/api/v1/client-records?limit=10000&page=1`, {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`
      }
    }).then(async (response) => {
      const resultJSON = await response.json()
      dispatch({
        type: 'GET_ALL_DATA_EXPEDIENTSV1_LEASE',
        data: resultJSON.items
      })
      dispatch(setLoadingApp(false));
    }).catch(err => console.log(err))
  }
}