const { createContext } = require('react');

const AdditionalThemeContext = createContext({
  dataTableStyles: {},
  dataTableButtonStyles: {},
  dataTableIconSize: 0
});

const AdditionalThemeContextProvider = ({ children }) => {
  const dataTableStyles = {
    rows: {
      style: {
        // Set DataTable's rows minHeight
        minHeight: '32px'
      }
    }
  };

  // Set DataTable's action button size
  const dataTableButtonStyles = {
    width: 38,
    height: 38,
    padding: 0
  };

  // Set DataTable's action button's icon size
  const dataTableIconSize = 16;

  return (
    <AdditionalThemeContext.Provider
      value={{ dataTableStyles, dataTableButtonStyles, dataTableIconSize }}
    >
      {children}
    </AdditionalThemeContext.Provider>
  );
};

export { AdditionalThemeContext, AdditionalThemeContextProvider };
