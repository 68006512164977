import axios from 'axios';

export const FIND_LV2_LEASE_CONCEPT = 'FIND_LV2_LEASE_CONCEPT';
export const POST_LV2_LEASE_CONCEPT = 'POST_LV2_LEASE_CONCEPT';
export const PUT_LV2_LEASE_CONCEPT = 'PUT_LV2_LEASE_CONCEPT';
export const RESET_LV2_LEASE_CONCEPT = 'RESET_LV2_LEASE_CONCEPT';

/**
 * Obtiene un concepto de arrendamiento por su ID.
 * @param {number} id - ID del concepto.
 * @param {string} token - Token de autorización.
 */

export const findLeaseConcept = (id, token) => (dispatch) => {
  axios({
    url: `${process.env.REACT_APP_NESTJS_API}/api/v1/concept-lease/${id}`,
    headers: {
      authorization: `Bearer ${token}`
    }
  })
    .then(({ data }) => {
      dispatch({
        type: FIND_LV2_LEASE_CONCEPT,
        data
      });
    })
    .catch((error) => {
      // Manejar el error adecuadamente
    });
};

export const postLeaseConcept = (data, token) => (dispatch) => {
  axios({
    method: 'post',
    url: `${process.env.REACT_APP_NESTJS_API}/api/v1/concept-lease/bulk`,
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  })
    .then(({ data }) => {
      dispatch({
        type: POST_LV2_LEASE_CONCEPT,
        data
      });
    })
    .catch((error) => {
      // Manejar el error adecuadamente
    });
};

export const putLeaseConcept = (data, token) => (dispatch) => {
  axios({
    method: 'put',
    url: `${process.env.REACT_APP_NESTJS_API}/api/v1/concept-lease/bulk`,
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  })
    .then(({ data }) => {
      dispatch({
        type: PUT_LV2_LEASE_CONCEPT,
        data
      });
    })
    .catch((error) => {
      // Manejar el error adecuadamente
    });
};

export const resetLeaseConceptState = () => ({
  type: RESET_LV2_LEASE_CONCEPT
});