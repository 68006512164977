// ** Redux Imports
import { combineReducers } from 'redux';

// ** Reducers Imports
import auth from './auth';
import navbar from './navbar';
import layout from './layout';
import funder from './funder';

/* PORTFOLIO */
// import leaseCombined from '@src/views/sqn/lease-combined/store/reducer';
import leaseDetailReducer from '@src/views/modules/onboarding/lease/store/reducer/leaseDetail.reducer';
import leaseCombinedMF from '@src/views/sqn/master-file/store/reducer';
import portfolio from '@src/views/sqn/portfolio/store/reducer';
import customers from '@src/views/sqn/customers/store/reducer';
// import reports from '@src/views/sqn/reports/store/reducer';
// import reportsModule from '@src/views/sqn/modules/reports/store/reducer'
// import lease from '@src/views/modules/onboarding/lease/store/reducer'
// import reportModule from '@src/views/modules/reports/store/reducer';
// import portfolioModule from '@src/views/modules/portfolio/store/reducer';

/* QUANTUM REDUCER */
import expedientNestJS from '@src/views/modules/onboarding/expedient/store/reducer';
import cxcNestJS from '@src/views/modules/onboarding/auditable/store/reducer';
import cxcAccounting from '@src/views/modules/onboarding/accounting/store/reducer';
import quotationsNestJS from '@src/views/modules/onboarding/quotations/store/reducer';
import leaseNestJS from '@src/views/modules/onboarding/lease/store/reducer';
import leaseNestV2JS from '@src/views/modules/onboarding/lease-v2/store/reducer';
import general from '@src/views/modules/utility/store/reducer';
import admin from '@src/views/modules/admin/store/reducers';
import reportV2 from '@src/views/modules/reports/v2/store/reducer';
import client from '@src/views/modules/onboarding/lease-v2/components/Lease/ClientBillingModule/store/reducers';
import app from '@quantumv2/stores/app/reducer';

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  funder,
  /* SQN REDUCER */
  // leaseCombined,
  leaseDetailReducer,
  leaseCombinedMF,
  portfolio,
  customers,
  // reports,
  // lease,
  // reportModule,
  // reportsModule
  admin,
  reportV2,
  /* PMS MODULES*/
  app,
  general,
  // portfolioModule,
  /* NestJS */
  client,
  leaseNestJS,
  leaseNestV2JS,
  quotationsNestJS,
  expedientNestJS,
  cxcNestJS,
  cxcAccounting
});

export default rootReducer;
