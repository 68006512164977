import { makeQuantumRequest } from '../../../../../../auth/request/makeRequest';

export const GET_ASSUMP_LEASE = '[LEASE_DETAIL]_GET_ASSUMP_LEASE';
export const GET_ASSET_LEASE = '[LEASE_DETAIL]_GET_ASSET_LEASE';
export const GET_PAYMENTS_LEASE = '[LEASE_DETAIL]_GET_PAYMENTS_LEASE';
export const GET_RESYNC_LEASE = '[LEASE_DETAIL]_GET_RESYNC_LEASE';
export const GET_LEASE_BY_ID = '[LEASE_DETAIL]_GET_LEASE_BY_ID';

export const getLeaseAssump = (id, token) => async (dispatch) => {
  try {
    const response = await makeQuantumRequest('/quotations/filter', { query: { idLease: id } }, token);
    const { data } = response;
    const item = data.items;
    const payload = item?.length ? item[0] : {};
    const type = GET_ASSUMP_LEASE;

    dispatch({ type, payload });
   
  } catch (err) {
    console.error('getLeaseAssump', err);
  }
};

export const getAssetLease = (id, token, setLoad = false) => async (dispatch) => {
  try {
    const vats = await makeQuantumRequest(`/leaseparameters/${id}`, { query: { includeVats: true } }, token);
    const facilityCfs = await makeQuantumRequest(`/leaseparameters/${id}`, { query: { includeFacilityCfs: true } }, token);
    const totalLeaseCfs = await makeQuantumRequest(`/leaseparameters/${id}`, { query: { includeTotalLeaseCfs: true } }, token);

    const payload = { ...vats?.data, ...facilityCfs?.data, ...totalLeaseCfs?.data };
    dispatch({
      type: GET_ASSET_LEASE,
      payload
    });

    setLoad(true);
  } catch (err) {
    console.error('getAssetLease', err);
  }
};

export const getPaymentsLease = (id, token, setLoad = false) => async (dispatch) => {
  try {
    const payments = await makeQuantumRequest(`/payments/${id}`, {}, token)    
    const { data } = payments;
    const payload = data?.length ? data : [];
    dispatch({
      type: GET_PAYMENTS_LEASE,
      payload
    });

    setLoad(true);
  } catch (err) {
    console.error('getPaymentsLease', err);
  }
};

export const getLeaseById = (id, token, setLoad = false) => async (dispatch) => {
  try {
    const leaseById = await makeQuantumRequest(`/leaseparameters/${id}`, {}, token)   
    const { data } = leaseById;
    const payload = data ? data : [];
   
    dispatch({
      type: GET_LEASE_BY_ID,
      payload
    });

    setLoad(true);
  } catch (err) {
    console.error('getLeaseById', err);
  }
};

export const getReSync = (id, token, setLoad = false) => async (dispatch) => {
  try {
    const response = await makeQuantumRequest(`/sync-lease-v1/resync/${id}`, {}, token)
    const { data } = response;
    const payload = data?.length ? data : [];
    
    await dispatch(getLeaseAssump(id, token));
    await dispatch(getAssetLease(id, token, () => {}));
    
    dispatch({
      type: GET_RESYNC_LEASE,
      payload
    });

    await dispatch(getPaymentsLease(id, token, setLoad));
    setLoad(false);
  } catch (err) {
    console.error('getReSync', err);
  }
};