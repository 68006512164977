import * as Actions from '../action';

// ** Initial State
const initialState = {
  data: {},
  assetData: {},
  payments: [],
  resync: {},
  leaseData: {}
};

const leaseDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_ASSUMP_LEASE:
      return { ...state, data: { ...action.payload } };
    case Actions.GET_ASSET_LEASE:
      return { ...state, assetData: { ...action.payload } };
    case Actions.GET_LEASE_BY_ID:
        return { ...state, leaseData: { ...action.payload } };
    case Actions.GET_PAYMENTS_LEASE:
      return { ...state, payments: [...action.payload] };
    case Actions.GET_RESYNC_LEASE:
      return { ...state, resync: [...action.payload] };
    default:
      return { ...state };
  }
};

export default leaseDetailReducer;
