// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability'
import { ToastContainer } from 'react-toastify'
import { AbilityContext } from './utility/context/Can'
import { ThemeContext } from './utility/context/ThemeColors'
import { IntlProviderWrapper } from './utility/context/Internationalization'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** Fake Database
import './@fake-db'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** Service Worker
import * as serviceWorker from './serviceWorker'
import { BrowserRouter as Router } from "react-router-dom";
import Auth0ProviderWithHistory from "./auth0/auth0-provider-with-history";

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { AdditionalThemeContextProvider } from './utility/context/AdditionalTheme'

Sentry.init({
  dsn: "https://0525fdc9c5684be6b2f726a3c6f85f0f@o4504283090780160.ingest.sentry.io/4504283092156416",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development'
});

const appRoot = document.getElementById('root')
  appRoot.setAttribute('class', 'notranslate')
  appRoot.setAttribute('translate', 'no')

ReactDOM.render(
  <Router>
    <Auth0ProviderWithHistory>
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <AbilityContext.Provider value={ability}>
            <ThemeContext>
              <AdditionalThemeContextProvider>
                <IntlProviderWrapper>
                  <LazyApp />
                  <ToastContainer newestOnTop />
                </IntlProviderWrapper>
              </AdditionalThemeContextProvider>
            </ThemeContext>
          </AbilityContext.Provider>
        </Suspense>
      </Provider>
    </Auth0ProviderWithHistory>
  </Router>,
  appRoot
)
// document.getElementById('root')
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
