import * as Actions from '../action';

const initialGlobal = {
  loading: false,
  socket: null,
  collapseItems: false
};

const initialCalculator = {
  data: null,
  activeStep: 0,
  idClient: 0,
  responses: {},
  loading: false,
  loadingApp: false,
  error: false,
  progress: 0
};

// ** Initial State
const initialState = {
  allLeases: [],
  loading: [],
  data: [],
  assets: [],
  quote: {},
  selectLease: {},
  syncLease: {},
  total: 1,
  params: {},
  selectedLease: null,
  newStatus: '',
  /* GLOBALS APP V2 */
  global: initialGlobal,
  /* GENERATE CALCULATOR PROPS */
  calculator: initialCalculator
  //initialsConcepts: []
};

const LeasesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FIND_NEST_LEASES':
      return { ...state, allLeases: action.data };
    case 'GET_NEST_LEASES':
      return { ...state, selectLease: action.data };
    case 'GET_NEST_PRINT_LEASES':
      return { ...state, selectPrint: action.data };
    case 'SYNC_NEST_LEASES':
      return { ...state, selectLease: action.data };
    case 'DELETE_NEST_LEASES':
      const updateDeletedAllLeases = state.allLeases.filter(lease => lease.idLeaseParameters !== action.idLeaseParameters);
      return { ...state, allLeases: updateDeletedAllLeases };
    case 'UPDATE_NEST_LEASES':
      const updatedAllLeases = state.allLeases.map(lease => {
        if (lease.idLeaseParameters === action.data.idLeaseParameters) {
          return { ...lease, ...action.data };
        }
        return lease;
      });
      return { ...state, allLeases: updatedAllLeases };
    case 'NEW_UPDATE_NEST_LEASES':
      const newAllLeases = [action.data, ...state.allLeases];
      return { ...state, allLeases: newAllLeases };
    case 'POST_NEST_LEASES':
      return { ...state, quote: action.data };
    case 'PATCH_NEST_LEASES':
      return { ...state, quote: action.data };
    case 'GET_DATA_ASSETS_LEASES':
      return { ...state, assets: action.data };
    case 'SYNC_NEST_QUOTE_LEASE':
      return { ...state, syncLease: action.data };
    case 'PATCH_NEST_LEASE':
      return { ...state, newStatus: action.data };
    case 'PATCH_NEST_LEASE_BULK':
      return { ...state, newLeases: action.data };
    // case 'SET_LOADING_V2':
    //   return { ...state, loading: action.payload };
    // case 'GET_NEST_INITIALS_CONCEPTS':
    //       return { ...state, catInitialsConcepts: action.data }
    /* GENERATE CALCULATOR ACTIONS */
    case Actions.HANDLE_STEP:
      return {
        ...state,
        calculator: { ...state.calculator, activeStep: action.payload }
      };
    case Actions.SET_SYNC_LOADING:
      return {
        ...state,
        calculator: { ...state.calculator, loading: action.payload }
      };
    case Actions.SET_SELECTED_ID_CLIENT:
      return {
        ...state,
        calculator: { ...state.calculator, idClient: action.payload }
      };
    case Actions.SET_SYNC_ERROR:
      return {
        ...state,
        calculator: { ...state.calculator, error: action.payload }
      };
    case Actions.SET_CALCULATOR_PROGRESS:
      return {
        ...state,
        calculator: { ...state.calculator, progress: action.payload }
      };
    case Actions.SET_QUOTATION_DATA:
      return {
        ...state,
        calculator: { ...state.calculator, data: action.payload }
      };
    case Actions.SET_QUERY_RESPONSE:
      const { queryNumber, response } = action.payload;
      return {
        ...state,
        calculator: {
          ...state.calculator,
          responses: { ...state.calculator.responses, [queryNumber]: response }
        }
      };
    case Actions.CLEAR_CALCULATOR_STATE:
      return {
        ...state,
        calculator: initialCalculator
      };
    case Actions.SET_LOADING_V2:
      return { 
        ...state,
        global: { ...state.global, loading: action.payload }
      };
    case Actions.SET_COLLAPSE_MENU_V2:
      return { 
        ...state,
        global: { ...state.global, collapseItems: action.payload }
      };
    default:
      return { ...state };
  }
};
export default LeasesReducer;
