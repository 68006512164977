export const SET_LOADING_V2 = 'SET_LOADING_VIEW_V2';

export const setLoadingApp = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_V2,
      payload
    });
  };
};

export const getNestJSLocalLease = (data) => {
  if (!data) {
    return false;
  }

  return async (dispatch) => {
    dispatch(setLoadingApp(true));
    dispatch({
      type: 'GET_NEST_ACCOUNTING',
      data: data
    });
    dispatch(setLoadingApp(false));
  };
};

export const SET_COLLAPSE_MENU_V2 = 'SET_COLLAPSE_MENU_VIEW_V2';

export const setCollapseItems = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_COLLAPSE_MENU_V2,
      payload
    });
  };
};

export const getAllDataAccounting = (token, filter) => {
  return dispatch => {
    dispatch(setLoadingApp(true));
    return fetch(`https://cxc-dev.quantum-lease.com/api/cxc/accounting-data`, {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`,
        type: "payments"
      }
    }).then(async (response) => {
      const resultJSON = await response.json()
      console.log('resultAccounting', resultJSON)
      dispatch({
        type: 'GET_ALL_DATA_ACCOUNTING',
        data: resultJSON.items
      })
      dispatch(setLoadingApp(false));
    }).catch(err => console.log(err))
  }
}