// ** Redux Imports
import { combineReducers } from 'redux';
import clientReducer from './client.reducer';
import conceptLeaseReducer from './concept-lease.reducer';
// import userReducer from './user.reducer';

const adminReducer = combineReducers({
  clientReducer,
  conceptLeaseReducer
});

export default adminReducer;
